import React, { Component } from "react";
import Chart from "react-google-charts";
import "./style.scss"
import Ansi from "ansi-to-react";
import { Row , Col} from 'reactstrap';
var Convert = require('ansi-to-html');
var convert = new Convert();

class JupyterIframeComponent extends Component {


    constructor() {
        super();
        var pathes = decodeURI(window.location.search)
            .replace('?', '')
            .split('&')
            .map(param => param.split('='))
            .reduce((values, [ key, value ]) => {
                values[ key ] = value
                return values
            }, {});

        this.state = {
            path: pathes.q,
            socket: null,
            infos: []
        }

        this.reloadData = this.reloadData.bind(this);
        this.onOpen = this.onOpen.bind(this);
        this.onMessage = this.onMessage.bind(this);
        this.appendInfoConsole = this.appendInfoConsole.bind(this);
        this.appendErrorConsole = this.appendErrorConsole.bind(this);
        this.reloadData();
    }

    onMessage(message) {
        console.log(message);

        var data = JSON.parse(message.data);
        if (data.type == "log"){
            this.appendInfoConsole(data.text);
        }
        if (data.type == "error"){
            this.appendErrorConsole(data.text);
        }

    }

    onOpen(event){
        console.log(event);
        var data = {
            type: "registerConsole",
            path: this.state.path
        };
        this.state.socket.send(JSON.stringify(data));

    }

    appendInfoConsole(text) {
        this.state.infos.push({text: text, type: "info"});
        this.setState({infos: this.state.infos});

        window.scrollTo(0,document.body.scrollHeight);

    }

    appendErrorConsole(text) {
        this.state.infos.push({text: text, type: "error"});
        this.setState({infos: this.state.infos});

        window.scrollTo(0,document.body.scrollHeight);
    }

    reloadData() {
        var _this = this;
        let port = "";
        if (window.location.hostname.includes("localhost")) {
            port = ":9000";
        }
        var path = window.location.hostname + port + "/api/jupyter/tumbler";
        // Connect to websocket
        this.state.socket = new WebSocket("ws://"+path);

        this.state.socket.onopen = function(event) {
            _this.onOpen(event);
        }

        this.state.socket.onmessage = function(event) {
            _this.onMessage(event);
        };

    }

    printMessages() {
        var l = [];
        for (var i=0; i<this.state.infos.length; i++)
        {
            var m = this.state.infos[i];
            if (m.type == "error") {

                l.push( <div style={{color: "red", fontWeight: "bold"}} ><Ansi>{m.text}</Ansi></div>)
            }
            else  {
                l.push( <div><Ansi>{m.text}</Ansi></div>)

            }
        }
        return l;
    }


    render() {

        var messages = this.printMessages();

        return (
            <div>
                <div className={"toolbar"}>
                    <Row>
                        <Col xs={"2"} className={"title"}>
                            Coindeck
                        </Col>
                        <Col xs={"2"} className={"title"}>
                            <Row>
                                <Col><button >X</button></Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <div className={"logmessages"}>
                    {messages}
                </div>
            </div>
        );
    }
}

export default JupyterIframeComponent;
